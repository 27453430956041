import React from 'react';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { AnyfiddleLogoSVG } from '../AnyfiddleLogo/AnyfiddleLogo';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="fixed-width-container">
        <div className="footer-row">
          <div className="footer-column logo-column">
            <img src="/logo192.png" alt="Anyfiddle logo" />
            <AnyfiddleLogoSVG isPro={false} />
          </div>

          <div className="footer-column">
            <div className="footer-section">
              <h4>Company</h4>
              <ul>
                {/* <li>
                  <a href="/blog">Blog</a>
                </li> */}
                <li>
                  <a href="https://docs.anyfiddle.com">Documentation</a>
                </li>
                <li>
                  <Link to="/terms-of-use/">Terms of use</Link>
                </li>
                <li>
                  <Link to="/privacy-policy/">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-section">
              <h4>Social</h4>
              <ul>
                <li>
                  <a
                    href="https://twitter.com/anyfiddle"
                    target="twitter-social"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter as IconProp}
                      fixedWidth={true}
                      className="footer-social-logo"
                    />
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/anyfiddle"
                    target="fb-social"
                  >
                    <FontAwesomeIcon
                      icon={faFacebookF as IconProp}
                      fixedWidth={true}
                      className="footer-social-logo"
                    />
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/anyfiddle"
                    target="linkedin-social"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin as IconProp}
                      fixedWidth={true}
                      className="footer-social-logo"
                    />
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-section">
              <h4>Contact Us</h4>
              <ul>
                <li>
                  <a href="mailto:hello@anyfiddle.com">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      fixedWidth={true}
                      className="footer-social-logo"
                    />
                    hello@anyfiddle.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-section">
              <h4>Support</h4>
              <ul>
                <li>
                  <a href="mailto:support@anyfiddle.com">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      fixedWidth={true}
                      className="footer-social-logo"
                    />
                    support@anyfiddle.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright-column">
          Copyright &copy; 2021 Anyfiddle.com All rights reserved.
        </div>
      </div>
    </footer>
  );
}

import React from 'react';
import { observer } from 'mobx-react-lite';

import './AnyfiddleLogo.scss';
import { Link } from 'react-router-dom';
import { useStore } from '../../stores';

function ProSVGGroup() {
  return (
    <g className="pro-badge">
      <rect
        className="pro-rect"
        width="61"
        height="28"
        rx="5"
        transform="translate(247 13)"
      />
      <path
        className="pro-text"
        d="M7.72-14H1.66V0H4.9V-3.86H7.72c3.74,0,6.08-1.94,6.08-5.06C13.8-12.06,11.46-14,7.72-14ZM7.54-6.5H4.9v-4.86H7.54c1.98,0,2.98.9,2.98,2.44C10.52-7.4,9.52-6.5,7.54-6.5ZM28.5,0,25.36-4.5a4.552,4.552,0,0,0,2.88-4.42c0-3.14-2.34-5.08-6.08-5.08H16.1V0h3.24V-3.9h2.98L25.02,0ZM24.96-8.92c0,1.52-1,2.44-2.98,2.44H19.34v-4.88h2.64C23.96-11.36,24.96-10.46,24.96-8.92ZM37.58.24c4.4,0,7.66-3.06,7.66-7.24s-3.26-7.24-7.66-7.24c-4.42,0-7.66,3.08-7.66,7.24S33.16.24,37.58.24Zm0-2.76A4.271,4.271,0,0,1,34.412-3.8,4.271,4.271,0,0,1,33.2-7a4.271,4.271,0,0,1,1.212-3.2A4.271,4.271,0,0,1,37.58-11.48,4.271,4.271,0,0,1,40.748-10.2,4.271,4.271,0,0,1,41.96-7a4.271,4.271,0,0,1-1.212,3.2A4.271,4.271,0,0,1,37.58-2.52Z"
        transform="translate(255 34)"
      />
    </g>
  );
}

export function ProBadge({
  height,
  width,
}: {
  height?: number;
  width?: number;
}) {
  return (
    <svg
      viewBox="240 13 75 30"
      height={height && `${height}px`}
      width={width && `${width}px`}
    >
      <ProSVGGroup />
    </svg>
  );
}

export function AnyfiddleLogoSVG(props: { isPro: boolean }) {
  const { isPro } = props;
  const width = isPro ? 310 : 240;
  return (
    <svg className="anyfiddle-logo" viewBox={`0 0 ${width} 50`}>
      <g>
        <path
          className="anyfiddle-text"
          d="M27.41-40.472h7.826l-14.408-34H13.462L-.9-40.472H6.742L9.6-47.758H24.556ZM11.989-53.733,17.1-66.751l5.11,13.018Zm41.154-13.26a10.559,10.559,0,0,0-8.194,3.449V-66.6H38.09v26.133h7.181V-53.392c0-4.809,2.486-7.043,5.938-7.043,3.176,0,5.018,1.943,5.018,6.169v13.8h7.181V-55.433C63.408-63.4,58.989-66.993,53.143-66.993Zm33.972.389-7,17.73L73.167-66.6H65.756L76.482-40.277l-.092.243c-.967,2.332-2.071,3.255-4.051,3.255a6.182,6.182,0,0,1-4.051-1.652L65.664-33.04a10.625,10.625,0,0,0,6.9,2.38c4.465,0,7.872-1.894,10.265-8.209L94.02-66.6Zm35.63-3.643a4.232,4.232,0,0,0,3.2-1.2,4.232,4.232,0,0,0,1.269-3.17c0-2.332-1.8-4.08-4.465-4.08a4.2,4.2,0,0,0-3.152,1.141,4.2,4.2,0,0,0-1.313,3.085,4.2,4.2,0,0,0,1.313,3.084,4.2,4.2,0,0,0,3.152,1.141Zm-13.948-.68a4.845,4.845,0,0,1,2.9.923l1.887-5.489a9.762,9.762,0,0,0-5.478-1.409c-6.168,0-9.575,3.837-9.575,9.715v1.166h-3.82v5.829h3.821v19.721h7.181V-60.193h6.168v-5.829h-6.353v-1.117c0-2.575,1.151-3.789,3.272-3.789Zm10.357,30.456h7.181V-66.6h-7.181Zm31.9-36.043v12.678a9.271,9.271,0,0,0-7.411-3.157c-7,0-12.383,5.246-12.383,13.455s5.386,13.455,12.383,13.455a9.3,9.3,0,0,0,7.734-3.4v3.012h6.859V-76.514ZM144.887-46.3c-3.591,0-6.353-2.72-6.353-7.238s2.762-7.238,6.353-7.238c3.545,0,6.307,2.72,6.307,7.238S148.432-46.3,144.887-46.3ZM182.91-76.514v12.678a9.271,9.271,0,0,0-7.411-3.157c-7,0-12.383,5.246-12.383,13.455S168.5-40.083,175.5-40.083a9.3,9.3,0,0,0,7.734-3.4v3.012h6.859V-76.514ZM176.742-46.3c-3.591,0-6.353-2.72-6.353-7.238s2.762-7.238,6.353-7.238c3.545,0,6.307,2.72,6.307,7.238S180.286-46.3,176.742-46.3Zm19.978,5.829h7.18V-76.514h-7.18ZM234.835-53.44c0-8.4-5.616-13.552-12.889-13.552-7.549,0-13.211,5.635-13.211,13.455,0,7.772,5.57,13.455,14.132,13.455,4.465,0,7.918-1.457,10.219-4.226l-3.821-4.372a8.162,8.162,0,0,1-6.214,2.526c-3.775,0-6.4-1.992-7.089-5.246H234.7c.044-.632.136-1.458.136-2.041Zm-12.843-7.821a5.99,5.99,0,0,1,6.076,5.44h-12.2a5.965,5.965,0,0,1,6.124-5.441Z"
          transform="translate(2.9 79.659)"
        />
        {isPro && <ProSVGGroup />}
      </g>
    </svg>
  );
}

type Props = {
  isLink?: boolean;
};

const AnyfiddleLogo = observer((props: Props) => {
  const { userStore } = useStore();
  const { isProPlanUser } = userStore;
  const { isLink = true } = props;

  const logo = <AnyfiddleLogoSVG isPro={isProPlanUser} />;

  if (!isLink) {
    return logo;
  }
  return (
    <Link to="/" className="anyfiddle-logo-container-link">
      {logo}
    </Link>
  );
});

export default AnyfiddleLogo;

import { User } from '../types';

import { doc, getDoc } from 'firebase/firestore';
import { auth, db, signOut } from '../firebase';

type UserInDB = {
  id: string;
  username: string;
  email: string;
  name?: string;
  avatar?: string;
};
class UserService {
  async getUserFromDB(): Promise<User | null> {
    const email = this.getFirebaseUserEmail();
    if (!email) {
      return null;
    }

    const docRef = doc(db, `users`, email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const userInDB = docSnap.data() as UserInDB;
      const user = this.transformUser(userInDB);
      return user;
    }
    return null;
  }

  async signOut(): Promise<void> {
    await signOut();
  }

  transformUser(userInDB: UserInDB): User {
    const user: User = {
      id: userInDB.id,
      username: userInDB.username,
      email: userInDB.email,
      name: userInDB.name,
      avatar: userInDB.avatar,
    };
    return user;
  }

  getFirebaseUserEmail(): string | null {
    const currentAuthUser = auth.currentUser;
    if (!currentAuthUser) {
      return null;
    }

    if (currentAuthUser.email) {
      return currentAuthUser.email;
    } else if (currentAuthUser.providerData.length > 0) {
      return currentAuthUser.providerData[0].email;
    }
    return null;
  }
}

const userService = new UserService();
export default userService;

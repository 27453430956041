import { User } from '../types';

const PROJECT_START_REQUEST_MARKER = 'PROJECT_START_REQUEST_MARKER';
const PROJECT_START_MARKER = 'PROJECT_START_MARKER';
const PROJECT_CONNECT_MARKER = 'PROJECT_CONNECT_MARKER';
const PROJECT_WORKSPACE_INIT_MARKER = 'PROJECT_WORKSPACE_INIT_MARKER';

const measureDuration = (marker1: string, marker2: string): number => {
  const name = 'measure-duration';
  window.performance.measure(name, marker1, marker2);
  const entries = window.performance.getEntriesByName(name);
  if (entries.length === 0) {
    return 0;
  }
  const entry = entries[entries.length - 1];
  const duration = entry.duration;
  window.performance.clearMeasures(name);
  return Math.round(duration);
};

const mark = (marker: string): void => {
  window.performance.clearMarks(marker);
  window.performance.mark(marker);
};

class AnalyticsService {
  onLogin(userId: string) {}

  onUser(user: User) {
    console.log('[TELEMETRY] On user', user.username);
    if (!user.isAnonymous) {
      if (window.analytics) {
        window.analytics.identify(user.id, {
          name: user.name,
          email: user.email,
        });
      }
    }
  }

  onSignOut() {
    if (window.analytics) {
      window.analytics.reset();
    }
  }

  onPageLoad(pageType: string, pageId?: string) {
    const pageProps: { pageId?: string } = {};

    if (pageId) {
      pageProps.pageId = pageId;
    }
    if (window.analytics) {
      window.analytics.page(pageType);
    }
  }

  onProjectCreateFromTemplate(templateId: string) {
    if (window.analytics) {
      window.analytics.track('Project - Create from template', {
        templateId,
      });
    }
  }

  onProjectCreateFromGitUrl(gitUrl: string) {
    if (window.analytics) {
      window.analytics.track('Project - Create from Git url', {
        gitUrl,
      });
    }
  }

  onProjectOpenFromMyProjects(projectId: string) {
    if (window.analytics) {
      window.analytics.track('Project - Open from my projects', {
        projectId,
      });
    }
  }

  onProjectCreateFromShare(originalProjectId: string) {
    if (window.analytics) {
      window.analytics.track('Project - Create from share', {
        originalProjectId,
      });
    }
  }

  onProjectDelete(projectId: string) {
    if (window.analytics) {
      window.analytics.track('Project - Delete', {
        projectId,
      });
    }
  }

  onProjectTitleChange(projectId: string, title: string) {
    if (window.analytics) {
      window.analytics.track('Project - Title change', {
        projectId,
        title,
      });
    }
  }

  onProjectIsPublicChange(projectId: string, isPublic: boolean) {
    if (window.analytics) {
      window.analytics.track('Project - Is public change', {
        projectId,
        isPublic,
      });
    }
  }

  onProjectPortSet(projectId: string, port: number) {
    if (window.analytics) {
      window.analytics.track('Project - Port set', {
        projectId,
        port,
      });
    }
  }

  onProjectStartRequest(projectId: string) {
    mark(PROJECT_START_REQUEST_MARKER);
  }

  onProjectStart(projectId: string) {
    mark(PROJECT_START_MARKER);
    const duration = measureDuration(
      PROJECT_START_REQUEST_MARKER,
      PROJECT_START_MARKER
    );
    console.log(`[TELEMETRY] Project Started in ${duration / 1000}s`);
    if (window.analytics) {
      window.analytics.track('Project - Start', {
        projectId,
        duration: duration / 1000,
      });
    }
  }

  onProjectConnect(projectId: string) {
    mark(PROJECT_CONNECT_MARKER);
    const duration = measureDuration(
      PROJECT_START_MARKER,
      PROJECT_CONNECT_MARKER
    );

    console.log(`[TELEMETRY] Project connected in ${duration / 1000}s`);
    if (window.analytics) {
      window.analytics.track('Project - Connect', {
        projectId,
        duration: duration / 1000,
      });
    }
  }

  onProjectInitWorkspace(projectId: string) {
    mark(PROJECT_WORKSPACE_INIT_MARKER);
    const duration = measureDuration(
      PROJECT_CONNECT_MARKER,
      PROJECT_WORKSPACE_INIT_MARKER
    );

    console.log(
      `[TELEMETRY] Project workspace intialized in ${duration / 1000}s`
    );
    if (window.analytics) {
      window.analytics.track('Project - Workspace init', {
        projectId,
        duration: duration / 1000,
      });
    }
  }

  onProjectStop(projectId: string) {
    if (window.analytics) {
      window.analytics.track('Project - Stop', { projectId });
    }
  }

  onProjectTimeout(projectId: string) {
    if (window.analytics) {
      window.analytics.track('Project - Timeout', { projectId });
    }
  }

  onProjectActive() {
    if (window.analytics) {
      window.analytics.track('Project - Active');
    }
  }

  onProjectInactive() {
    if (window.analytics) {
      window.analytics.track('Project - Inactive');
    }
  }

  onTerminalTitleChange(title: string) {
    if (window.analytics) {
      window.analytics.track('Terminal - Title change', {
        terminalTitle: title,
      });
    }
  }

  onTerminalRun(command: string) {
    if (window.analytics) {
      window.analytics.track('StatusBar - Run click', {
        command,
      });
    }
  }

  onStatusbarPreviewClick() {
    if (window.analytics) {
      window.analytics.track('StatusBar - Preview click');
    }
  }
}

const analyticsService = new AnalyticsService();
export default analyticsService;

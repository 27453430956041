import React from 'react';
import { Redirect, Router } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import './App.scss';

import Home from './pages/home/Home';
import { History } from 'history';

import Toast from './components/Toast/Toast';
import TermsOfUse from './pages/terms-of-use/TermsOfUse';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';

type AppProps = {
  history: History;
};

export default function App(props: AppProps) {
  return (
    <Router history={props.history}>
      <Toast />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/terms-of-use">
          <TermsOfUse />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>

        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}

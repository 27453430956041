import React from 'react';
import './SignInAction.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from '../../stores';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import Tippy from '@tippyjs/react';
import UserMenu from '../UserMenu/UserMenu';
import { observer } from 'mobx-react-lite';
import { Overlay } from '../Overlay/Overlay';
import Loader from '../Loader/Loader';
import { Link, useLocation } from 'react-router-dom';

type Props = {
  showUsername?: boolean;
  isSignInLink?: boolean;
};

const SignInAction = observer((props: Props) => {
  const { userStore } = useStore();
  const { user, openSignInModal, isSigningOut } = userStore;
  const { showUsername = false, isSignInLink = false } = props;
  const location = useLocation();

  const handleSignInClick = () => {
    openSignInModal();
  };

  return (
    <div className="sign-in-action">
      {isSigningOut && (
        <Overlay className="signing-out-overlay">
          <Loader>Signing out...</Loader>
        </Overlay>
      )}
      {(!user || user.isAnonymous) && (
        <>
          {isSignInLink ? (
            <Link
              className="login-button"
              to={`/sign-in?returnUrl=${location.pathname}`}
            >
              <FontAwesomeIcon icon={faSignInAlt} />
              Sign In
            </Link>
          ) : (
            <button className="login-button" onClick={handleSignInClick}>
              <FontAwesomeIcon icon={faSignInAlt} />
              Sign In
            </button>
          )}
        </>
      )}
      {user && !user.isAnonymous && (
        <Tippy
          content={<UserMenu />}
          placement="bottom-end"
          trigger="click"
          interactive={true}
          arrow={false}
          theme="menu"
          offset={[0, 0]}
          appendTo={document.body}
        >
          <button className="user-button">
            <div
              className="user-avatar"
              style={{ backgroundImage: `url(${user.avatar || ''})` }}
            />
            {showUsername && (
              <div className="user-username">@{user.username}</div>
            )}
          </button>
        </Tippy>
      )}
    </div>
  );
});

export default SignInAction;

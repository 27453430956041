import React, { useRef, useEffect, useState, Fragment } from 'react';
import './Home.scss';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '../../stores';
import { observer } from 'mobx-react-lite';
import {
  faGithub,
  faSlack,
  faStackOverflow,
} from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import analyticsService from '../../services/AnalyticsService';
import Footer from '../../components/Footer/Footer';
import MainPageNav from '../../components/MainPageNav/MainPageNav';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';

const Home = observer(() => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { userStore } = useStore();
  const { fetchUser } = userStore;
  const [userFromFirebase] = useAuthState(auth);

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFromFirebase]);

  useEffect(() => {
    if (videoRef.current) {
      const videoEl = videoRef.current;
      videoEl.addEventListener('playing', () => {
        setIsPlaying(true);
      });
    }

    analyticsService.onPageLoad('Home');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlayClick = () => {
    if (videoRef.current) {
      const videoEl = videoRef.current;
      videoEl.play();
    }
  };

  return (
    <div className="home-page">
      <Helmet>
        <link rel="canonical" href="https://www.anyfiddle.com" />
        <title>Anyfiddle - The easiest way to code online</title>
      </Helmet>
      <div className="top-section">
        <div className="acquihire-notice">
          <div className="fixed-width-container">
            <h2>CodeSandbox acquihires Anyfiddle</h2>
            <div>
              Anyfiddle is getting acquihired by CodeSandbox and the creator of
              Anyfiddle will join the CodeSandbox team. Anyfiddle will no longer
              be operational for new users. Existing users can download their
              projects in Anyfiddle by logging in to their account.
            </div>
          </div>
        </div>
        <header className="fixed-width-container">
          <MainPageNav>
            <Fragment>
              <li>
                <a href="#features">Features</a>
              </li>
              <li>
                <a href="#pricing">Pricing</a>
              </li>
            </Fragment>
          </MainPageNav>
          <h1>The easiest way to code online</h1>
          <p>
            Build, run and share code in seconds with our free editor. No setup
            required.
          </p>
          <Link to="/projects/" className="button-green start-button">
            Download projects
          </Link>
          <div className="demo-container">
            {!isPlaying && (
              <div className="play-button" onClick={handlePlayClick}>
                <FontAwesomeIcon icon={faPlay} />
              </div>
            )}
            <video
              title="Editor experience"
              muted
              autoPlay
              loop={true}
              controls={false}
              poster="/images/demo6.png?v1"
              ref={videoRef}
            >
              <source src="/images/demo6.mp4?v1" type="video/mp4" />
              <source src="/images/demo6.webm?v1" type="video/webm" />
            </video>
          </div>
        </header>
      </div>
      <section id="features" className="features-section">
        <div className="fixed-width-container">
          <h2>Features</h2>
          <div className="feature-item">
            <div className="feature-content">
              <h3>
                Start coding right away with{' '}
                <span className="no-br">pre-built</span> templates
              </h3>
              <p>
                Choose from the templates for languages like NodeJS, Python,
                Ruby, Java or popular frameworks like Express, React, Laravel,
                Spring, and more.
              </p>
            </div>
            <div className="feature-image">
              <img
                width="400"
                height="350"
                src="/images/frameworks@2x.png"
                title="Frameworks and languages"
                alt="Get started in seconds using templates"
              />
            </div>
          </div>
          <div className="feature-item">
            <div className="feature-content">
              <h3>Create custom projects</h3>
              <p>Didn't find a template you need? Build your own project!</p>
              <p>
                Install the software, dependencies, and packages using familiar
                package managers like Aptitude, npm, yarn, pip, gem, and more.
              </p>
            </div>
            <div className="feature-image">
              <img
                width="420"
                height="220"
                src="/images/packages@2x.png"
                title="Terminal"
                alt="Terminal to install and run any framework/app"
              />
            </div>
          </div>
          <div className="feature-item">
            <div className="feature-content">
              <h3>Share your projects</h3>
              <p>
                Share projects with your friends, colleagues, mentors, and
                others by simply copying the URL. It is that easy!
              </p>
              <p>
                But there's more...
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faGithub as IconProp} />
                    Showcase your open source projects in Github
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faStackOverflow as IconProp} />
                    Answer questions in StackOverflow like a pro
                  </li>

                  <li>
                    <FontAwesomeIcon icon={faSlack as IconProp} />
                    Share a prototype with your colleagues via Slack/Email
                  </li>
                </ul>
              </p>
            </div>
            <div className="feature-image">
              <img
                width="450"
                height="450"
                src="/images/share@2x.png"
                title="Share projects"
                alt="Share projects with anyone by just copying url"
                style={{ opacity: 0.9 }}
              />
            </div>
          </div>
          <div className="feature-item">
            <div className="feature-content">
              <h3>Access your code from anywhere, anytime</h3>
              <p>
                It's all in the cloud and accessible from anywhere. Your code is
                just a few clicks away.
              </p>
              <p>
                Get the editing experience similar to that of VSCode and get
                full terminal access, all within a web browser.
              </p>
            </div>
            <div className="feature-image">
              <img
                width="470"
                height="302"
                src="/images/codeeditor.png?v4"
                title="Code Editor"
                alt="Code editor that can be accessed anywhere anytime"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="pricing" className="pricing-section">
        <div className="fixed-width-container">
          <h2>Pricing</h2>
          <div className="pricing-items-container">
            <div className="pricing-item">
              <h3>Free</h3>
              <div className="price-box">$0</div>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Unlimited public projects
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Project instance with 0.25 CPU and 0.5GB memory
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Share projects with anyone
                </li>
              </ul>
            </div>
            <div className="pricing-item">
              <h3>Pro</h3>
              <div className="price-box">
                $5
                <span className="pricing-per-month-text">per month</span>
              </div>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Unlimited private and public projects
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Project instance with 1 CPU and 4GB memory
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} />
                  Share projects with anyone or select users
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
});

export default Home;

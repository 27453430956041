import { Project } from '../types';

export function getProjectLastActveTime(project: Project): Date {
  if (project.lastActiveAt) {
    return project.lastActiveAt;
  } else if (project.lastStartedAt) {
    return project.lastStartedAt;
  } else {
    return project.createdAt;
  }
}

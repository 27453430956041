import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { db } from '../firebase';
import { Project } from '../types';

type ProjectInDB = {
  id: string;
  userId: string;
  isPublic: boolean;
  title: string | null;
  description?: string;
  image?: string;
  port?: number;
  parentTemplateId: string;
  parentProjectId?: string;
  parentGitUrl?: string;
  isForked: boolean;
  lastPingAt?: Timestamp;
  lastActiveAt?: Timestamp;
  lastStartedAt?: Timestamp;
  lastStateUpdatedAt?: Timestamp;
  createdAt: Timestamp;
};
class ProjectService {
  async getAllProjects(userId: string): Promise<Project[]> {
    const q = query(
      collection(db, `projects`),
      where('userId', '==', userId),
      where('isForked', '==', true)
    );
    const querySnapshot = await getDocs(q);
    const projects: Project[] = [];
    querySnapshot.forEach((docSnap) => {
      const projectInDB = docSnap.data() as ProjectInDB;
      projects.push(this.transformProject(projectInDB));
    });
    return projects;
  }

  async getProjectById(projectId: string): Promise<Project | null> {
    const docRef = doc(db, `projects`, projectId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const projectInDB = docSnap.data() as ProjectInDB;
      return this.transformProject(projectInDB);
    }
    return null;
  }

  transformProject(projectInDB: ProjectInDB): Project {
    const {
      title,
      lastStartedAt,
      lastPingAt,
      lastActiveAt,
      lastStateUpdatedAt,
      createdAt,
      ...rest
    } = projectInDB;

    const transformedProject: Project = {
      title: title ? title : null,
      ...rest,
      createdAt: createdAt.toDate(),
    };
    if (lastStartedAt) {
      transformedProject.lastStartedAt = lastStartedAt.toDate();
    }
    if (lastPingAt) {
      transformedProject.lastPingAt = lastPingAt.toDate();
    }
    if (lastActiveAt) {
      transformedProject.lastActiveAt = lastActiveAt.toDate();
    }
    if (lastStateUpdatedAt) {
      transformedProject.lastStateUpdatedAt = lastStateUpdatedAt.toDate();
    }
    return transformedProject;
  }
}

const projectService = new ProjectService();
export default projectService;

import React from 'react';
import ProjectStore from './ProjectStore';
import { RouterStore } from 'mobx-react-router';
import UserStore from './UserStore';
import UxStore from './UxStore';

export default class RootStore {
  public projectStore: ProjectStore;
  public routerStore: RouterStore;
  public userStore: UserStore;
  public uxStore: UxStore;

  constructor() {
    this.projectStore = new ProjectStore(this);
    this.routerStore = new RouterStore();
    this.userStore = new UserStore(this);
    this.uxStore = new UxStore(this);
  }
}

export const rootStore = new RootStore();

export const StoreContext = React.createContext<RootStore | null>(null);

export function useStore() {
  const store = React.useContext(StoreContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return store;
}

import { observer } from 'mobx-react-lite';
import React from 'react';
import AnyfiddleLogo from '../AnyfiddleLogo/AnyfiddleLogo';
import './MainPageNav.scss';

type Props = {
  children?: JSX.Element;
  showLogo?: boolean;
};

const MainPageNav = observer((props: Props) => {
  const { children, showLogo = true } = props;

  return (
    <nav className="main-page-nav">
      {showLogo && <AnyfiddleLogo />}
      <div className="grow" />
      <ul>{children}</ul>
    </nav>
  );
});

export default MainPageNav;

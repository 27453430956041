import React from 'react';
import './Toast.scss';
import {
  faCheck,
  faExclamation,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores';

export enum ToastType {
  SUCCESS,
  ERROR,
  WARN,
}

const Toast = observer(() => {
  const { uxStore } = useStore();
  const { toastMessage, toastType } = uxStore;

  if (toastMessage === null || toastType === null) {
    return null;
  }

  let toastIcon = faCheck;
  let toastClassName = 'success';

  switch (toastType) {
    case ToastType.SUCCESS:
      toastIcon = faCheck;
      toastClassName = 'success';
      break;

    case ToastType.ERROR:
      toastIcon = faExclamationCircle;
      toastClassName = 'error';
      break;

    case ToastType.WARN:
      toastIcon = faExclamation;
      toastClassName = 'warn';
      break;
  }

  return (
    <div className={`toast ${toastClassName}`}>
      <FontAwesomeIcon icon={toastIcon} />
      {toastMessage}
    </div>
  );
});

export default Toast;

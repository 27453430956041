import { initializeApp } from 'firebase/app';
import {
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  signOut as signOutFirebase,
} from 'firebase/auth';
import {
  getAuth,
  // connectAuthEmulator
} from 'firebase/auth';
import {
  getFirestore,
  // connectFirestoreEmulator
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

import config from './config';
import { AnyfiddleAuthProvider } from './types';

// import { getAnalytics } from 'firebase/analytics';

// Firebase intitialization
const firebaseConfig = config.firebase;
export const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth();
export const db = getFirestore();

// if (process.env.NODE_ENV === 'development') {
//   connectAuthEmulator(auth, 'http://localhost:9099');
//   connectFirestoreEmulator(db, 'localhost', 8002);
// }

export async function signInWithFirebase(
  authProvider: AnyfiddleAuthProvider
): Promise<void> {
  let provider:
    | GoogleAuthProvider
    | FacebookAuthProvider
    | GithubAuthProvider
    | null = null;
  switch (authProvider) {
    case AnyfiddleAuthProvider.FACEBOOK:
      provider = new FacebookAuthProvider();
      break;
    case AnyfiddleAuthProvider.GOOGLE:
      provider = new GoogleAuthProvider();
      provider.addScope('email');
      provider.addScope('profile');

      break;
    case AnyfiddleAuthProvider.GITHUB:
      provider = new GithubAuthProvider();
      provider.addScope('user:email');
      break;
  }
  if (provider) {
    try {
      await signInWithPopup(auth, provider);
      await updateUserOnLogin();
    } catch (e) {
      console.error(e);
    }
  }
}

export async function updateUserOnLogin(): Promise<void> {
  const functions = getFunctions();
  const updateUserWithUid = httpsCallable(functions, 'updateUserWithUid');
  await updateUserWithUid();
}

export async function getPrivateProjectLink(
  projectId: string
): Promise<string | null> {
  const functions = getFunctions();
  const getPrivateProjectLink = httpsCallable<
    { projectId: string },
    { url: string | null }
  >(functions, 'getPrivateProjectLink');
  const result = await getPrivateProjectLink({ projectId });
  return result.data.url;
}

export async function signOut(): Promise<void> {
  await signOutFirebase(auth);
}

export function getFirebaseStorageResourceUrl(
  orgId: string,
  fileHash: string
): string {
  const path = `resources/${orgId}/${fileHash}`;
  return `${config.firebase.storageHost}/v0/b/${
    config.firebase.storageBucket
  }/o/${encodeURIComponent(path)}?alt=media`;
}

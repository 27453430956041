import React from 'react';
import './Loader.scss';

type Props = {
  children?: JSX.Element | string;
};

export default function Loader(props: Props) {
  return (
    <div className="loader">
      <div className="loader-inner">
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div>{props.children || ''}</div>
      </div>
    </div>
  );
}

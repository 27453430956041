import React from 'react';
import './AppBar.scss';

import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import SignInAction from '../SignInAction/SignInAction';
import { useStore } from '../../stores';
import AnyfiddleLogo from '../AnyfiddleLogo/AnyfiddleLogo';

type Props = {
  children?: JSX.Element;
  showBackArrow?: boolean;
};

const AppBar = observer((props: Props) => {
  const { routerStore } = useStore();

  const { children, showBackArrow = false } = props;

  const handleBackClick = () => {
    routerStore.goBack();
  };

  return (
    <div className="app-bar">
      {showBackArrow && (
        <button className="back-button" onClick={handleBackClick}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
      )}
      <AnyfiddleLogo />

      <div className="grow" />
      <div className="app-bar-actions">{children}</div>

      <SignInAction />
    </div>
  );
});

export default AppBar;

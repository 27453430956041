import { observable, action, computed } from 'mobx';
import RootStore from '.';
import projectService from '../services/ProjectService';
import { Project } from '../types';
import _ from 'lodash';
import { getProjectLastActveTime } from '../utils/projects';

export default class ProjectStore {
  private rootStore: RootStore;

  @observable projects: Project[] = [];

  @observable isProjectsListLoaded: boolean = false;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get sortedProjects(): Project[] {
    return _.sortBy(
      this.projects,
      (project) => -1 * getProjectLastActveTime(project).getTime()
    );
  }

  @action.bound
  async loadProjectsList() {
    const user = this.rootStore.userStore.user;
    if (!user) {
      return;
    }
    this.isProjectsListLoaded = false;
    const projects = await projectService.getAllProjects(user.id);
    this.projects = projects;
    this.isProjectsListLoaded = true;
  }
}

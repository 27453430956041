const config = {
  apiDomain: process.env.REACT_APP_API_DOMAIN || 'dev-api.anyfiddle.com',
  timeoutInMins: 60,
  inactiveProjectTimeout: 60,
  mediaFileExtensions: {
    image: ['.jpg', '.jpeg', '.png', '.webp', '.gif'],
    video: ['.mp4', '.mpeg', '.avi', '.mov'],
    audio: ['.mp3', '.wav'],
    others: ['.pdf'],
  },
  maxTextFileSize: 1024 * 1024,
  maxUploadFileSize: 50 * 1024 * 1024,

  projectStartingTooLongDuration: 30 * 1000,
  projectStartingErrorDuration: 10 * 60 * 1000,

  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
    storageHost:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:9199'
        : 'https://firebasestorage.googleapis.com',
  },
};

export default config;

import React from 'react';
import './UserMenu.scss';

import { useStore } from '../../stores';
import {
  faSignOutAlt,
  faUser,
  faListAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const UserMenu = observer(() => {
  const { userStore, routerStore } = useStore();
  const { user, signOut } = userStore;
  const {
    location: { pathname },
  } = routerStore;

  if (!user) {
    return null;
  }

  const handleSignOutClick = () => {
    signOut();
  };

  return (
    <div className="user-menu">
      <div className="user-menu-section">
        <div className="user-details">
          <div className="user-name">{user.name || 'Unknown user'}</div>
          <div className="user-username">@{user.username}</div>
        </div>
      </div>
      <div className="user-menu-section">
        {pathname !== '/projects' && !user.isAnonymous && (
          <Link to="/projects/" className="user-menu-item">
            <FontAwesomeIcon icon={faListAlt} fixedWidth={true} /> My Projects
          </Link>
        )}
        <Link to="/account-settings/" className="user-menu-item">
          <FontAwesomeIcon icon={faUser} fixedWidth={true} /> Account Settings
        </Link>
      </div>
      <div className="user-menu-section">
        <button className="user-menu-item" onClick={handleSignOutClick}>
          <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
        </button>
      </div>
    </div>
  );
});
export default UserMenu;

import RootStore from '.';
import { observable, action } from 'mobx';
import userService from '../services/UserService';
import { User } from '../types';
import analyticsService from '../services/AnalyticsService';

export default class UserStore {
  private rootStore: RootStore;
  private reconnectTimer?: NodeJS.Timer;
  private shouldReconnect: boolean = false;

  @observable user: User | null = null;
  @observable isSignInModalOpen: boolean = false;
  @observable isSigningOut: boolean = false;
  @observable isUserSocketConnected: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action.bound
  async fetchUser(): Promise<User | null> {
    this.user = await userService.getUserFromDB();
    return this.user;
  }

  @action.bound
  async signOut(): Promise<void> {
    this.setIsSigningOut(true);
    await userService.signOut();
    analyticsService.onSignOut();

    return new Promise<void>((resolve) => {
      setTimeout(async () => {
        await this.fetchUser();
        this.setIsSigningOut(false);
        resolve();
      }, 1000);
    });
  }

  @action.bound
  openSignInModal() {
    this.isSignInModalOpen = true;
  }

  @action.bound
  closeSignInModal() {
    this.isSignInModalOpen = false;
  }

  @action.bound
  setIsSigningOut(isSigningOut: boolean) {
    this.isSigningOut = isSigningOut;
  }
}

import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { StoreContext, rootStore } from './stores';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import Helmet from 'react-helmet';
import Modal from 'react-modal';

const browserHistory = createBrowserHistory();

const history = syncHistoryWithStore(browserHistory, rootStore.routerStore);

const rootElement = document.getElementById('root');

Modal.setAppElement('#root');

const root = (
  <StoreContext.Provider value={rootStore}>
    <Helmet>
      <title>Anyfiddle</title>
      <meta
        name="description"
        content="Anyfiddle, the online code editor for everyone"
      />
    </Helmet>
    <App history={history} />
  </StoreContext.Provider>
);

if (rootElement && rootElement.hasChildNodes()) {
  hydrate(root, rootElement);
} else {
  render(root, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

navigator.serviceWorker.getRegistrations().then(function (registrations) {
  for (let registration of registrations) {
    registration.unregister();
  }
});

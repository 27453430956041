import React from 'react';
import './Overlay.scss';

import Modal from 'react-modal';
import classnames from 'classnames';

type Props = {
  className?: string;
  children: JSX.Element;
};
export function Overlay(props: Props) {
  const { children, className = '' } = props;
  return (
    <Modal
      className={classnames('overlay', {
        [className]: true,
      })}
      isOpen={true}
      overlayClassName="overlay-overlay"
      portalClassName="overlay-portal"
    >
      {children}
    </Modal>
  );
}

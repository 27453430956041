import { observable, action } from 'mobx';
import RootStore from '.';
import { ToastType } from '../components/Toast/Toast';

export default class UxStore {
  private rootStore: RootStore;

  @observable toastMessage: string | null = null;
  @observable toastType: ToastType | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action.bound
  showToast(message: string, toastType = ToastType.SUCCESS) {
    this.toastMessage = message;
    this.toastType = toastType;
    setTimeout(() => {
      this.hideToast();
    }, 3000);
  }

  hideToast() {
    this.toastMessage = null;
    this.toastType = null;
  }
}

export interface Project {
  id: string;
  userId: string;
  isPublic: boolean;
  title: string | null;
  description?: string;
  image?: string;
  port?: number;
  parentTemplateId: string;
  parentProjectId?: string;
  parentGitUrl?: string;
  isForked: boolean;
  lastPingAt?: Date;
  lastActiveAt?: Date;
  lastStartedAt?: Date;
  lastStateUpdatedAt?: Date;
  createdAt: Date;
  parentTemplate?: Template;
}

export type ProjectPermission = {
  canView: boolean;
  canEdit: boolean;
};

export enum ProjectLoadError {
  NOT_FOUND = 'NOT_FOUND',
  ACCESS_DENIED = 'ACCESS_DENIED',
  CLONING_NOT_ALLOWED = 'CLONING_NOT_ALLOWED',
}

export type Template = {
  id: string;
  name: string;
  description: string;
  priority: number;
  thumbnail: string;
  category: string;
  isPopular: boolean;
  tags: string[];
  image: string;
};
export type User = {
  id: string;
  username: string;
  email: string;
  name?: string;
  avatar?: string;
};

export type Plan = {
  id: string;
  name: string;
  stripeProductId?: string;
  stripePriceId?: string;
  canHavePrivateProjects: boolean;
  canCollaborate: boolean;
  cpu: number;
  memory: number;
  storage: number;
  monthlyRuntimeQuota: number;
  projectTimeout?: number;
  inactivityTimeout?: number;
};

export type ProjectFromAPI = {
  id: string;
  userId: string;
  isPublic: boolean;
  title?: string;
  description?: string;
  image?: string;
  port?: number;
  parentTemplateId: string;
  parentProjectId?: string;
  parentGitUrl?: string;
  isForked: boolean;
  lastPingAt?: string;
  lastActiveAt?: string;
  lastStartedAt?: string;
  lastStateUpdatedAt?: string;
  createdAt: string;
  deletedAt: string;
  createdByIp?: string;
  parentTemplate?: Template;
};

export enum AnyfiddleAuthProvider {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  GITHUB = 'github',
}
